export enum INTERVENTION_STATUT {
  EXECUTED = "réalisé",
  REPORT = "à l'étude",
  VALIDATE = "validé",
}

export enum LOCAL_STORAGE_STORE {
  USER_STORE = "USER_STORE",
  MISSION_TYPES_STORE = "MISSION_TYPES_STORE",
  AUTH_STORE = "AUTH_STORE",
}

export interface Location {
  id?: number;
  address: string;
  zipCode: string;
  city: string;
  countryCode: string;
}

export interface Company {
  id?: number;
  name?: string;
  location?: Location;
  billingLocation?: Location;
}

export interface Person {
  id: string;
  sellsyId?: number;
  lastName?: string;
  firstName?: string;
  phone?: string;
  email: string;
  role?: string;
  company?: Company;
  admin?: boolean;
  location?: Location;
  newsletter?: boolean;
}

export interface Auth {
  auth: Person;
  access_token?: string;
  refresh_token?: string;
  missionTypes?: MissionType[];
}

export interface Tokens {
  access_token: string;
  refresh_token: string;
}

export type SellsyCustomFieldType =
  | "boolean"
  | "simple-text"
  | "checkbox"
  | "select"
  | "radio"
  | "numeric"
  | "url"
  | "time"
  | "date"
  | "amount"
  | "numeric-with-unit";

export type FormFieldItem = { id: number; label: string };

export interface FormField {
  id: number;
  type: SellsyCustomFieldType;
  name: string;
  code: string;
  description: string;
  mandatory: boolean;
  items?: FormFieldItem[];
  min?: number;
  max?: number;
  unit?: string;
  value?: MissionDetailValue;
}

export interface MissionSpecificFields {
  constraints: FormField[];
  missionType: FormField[];
}

export interface MissionOtherFieldsGroup {
  id: number;
  name: string;
  fields: FormField[];
}

export interface MissionType {
  id: string;
  name: string;
}

export type MissionDetailNumberUnitValue = { number: string; unit_id: number };
export type MissionDetailAmountCurrencyValue = { amount: string; currency: string };

export type MissionDetailValue =
  | boolean
  | string
  | number
  | number[]
  | MissionDetailNumberUnitValue
  | MissionDetailAmountCurrencyValue;

export interface MissionDetail {
  formFieldId: number;
  value: MissionDetailValue;
}

export interface Location {
  address: string;
  zipCode: string;
  city: string;
  countryCode: string;
}

export interface MissionCreation {
  id?: number;
  contactId?: number;
  firstName?: string;
  lastName: string;
  phone?: string;
  role?: string;
  newsletter: boolean;
  contactLocation?: Location;
  companyName: string;
  companyLocation: Location;
  missionLocation: Location;
  missionGpsCoordinates?: { lat: string; lon: string };
  billingLocation?: Location;
  date: string;
  missionType: string;
  missionTypeConfig: MissionSpecificFields;
  missionContactName?: string;
  missionContactPhone?: string;
  details?: MissionDetail[];
}

export type EstimateStatus =
  | "draft"
  | "sent"
  | "read"
  | "accepted"
  | "refused"
  | "expired"
  | "advanced"
  | "partialinvoiced"
  | "invoiced"
  | "cancelled";

export interface Mission {
  id: number;
  status?: EstimateStatus;
  contact: Person;
  missionGpsCoordinates?: { lat: string; lon: string };
  date: string;
  realDate?: string;
  missionType: string;
  missionFields: MissionSpecificFields;
  details?: MissionDetail[];
  missionContact?: { name?: string; phone?: string };
  missionLocation?: Location;
  otherFields?: MissionOtherFieldsGroup[];
  notes?: {
    id: number;
    owner: { id: number; type: string };
    description: string;
    updated: string;
    created: string;
  }[];
  estimateNumber?: string;
  flyByLink?: string;
  myMapsLink?: string;
  prescriptor?: string;
}

export interface LightMission {
  id: number;
  status?: EstimateStatus;
  contact: Person;
  missionGpsCoordinates?: {
    lat: string;
    lon: string;
  };
  date: string;
  realDate?: string;
  missionType: string;
  details?: MissionDetail[];
  flyByLink?: string;
  myMapsLink?: string;
  missionLocation?: Location;
}

export interface Country {
  value: string;
  label: string;
}

export interface MissionsSpot {
  date: string;
  center?: { lat: string; lon: string };
}
