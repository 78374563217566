import { createTheme } from "@mui/material";
import { frFR } from "@mui/x-date-pickers/locales";

export const primaryAccentColor = "#259a8e";
export const secondaryAccentColor = "#fff";

const Theme = createTheme(
  {
    palette: {
      primary: {
        main: primaryAccentColor,
      },
      secondary: {
        main: secondaryAccentColor,
      },
      success: {
        main: primaryAccentColor,
      },
      info: {
        main: secondaryAccentColor,
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1em",
            backgroundColor: "rgba(0,0,0,0.7)",
            maxWidth: "400px",
          },
          arrow: {
            color: "rgba(0,0,0,0.7)",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          size: "small",
          variant: "outlined",
        },
      },
      MuiSelect: {
        defaultProps: {
          size: "small",
        },
      },
      MuiFormControl: {
        defaultProps: {
          size: "small",
        },
      },
      MuiButton: {
        styleOverrides: {
          containedSizeSmall: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
  },
  frFR,
);

export default Theme;
