import { FC } from "react";
import { CircularProgress, Button as MuiButton } from "@mui/material";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  className?: string;
  size?: "small" | "medium" | "large";
  isLoading?: boolean;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({ label, onClick, color, className, size, isLoading, disabled }) => (
  <div
    style={{
      position: "relative",
      display: "flex",
      justifyContent: "center",
    }}
    className={className}>
    <MuiButton
      disabled={isLoading || disabled}
      onClick={onClick}
      variant="contained"
      color={color}
      className={className}
      size={size}>
      {label}
    </MuiButton>
    {isLoading && (
      <CircularProgress
        size={24}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: -12,
          marginLeft: -12,
        }}
      />
    )}
  </div>
);

export default Button;
