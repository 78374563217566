import axios, { AxiosError } from "axios";

const EXPORT_URL = process.env.REACT_APP_API_EXPORT || "";

const exportMissionsData = (token: string, error: (message: string) => void): Promise<void> =>
  axios
    .get(EXPORT_URL, {
      method: "GET",
      responseType: "blob",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `AgrodroneMissions_${new Date().toISOString().split("T")[0]}.xlsx`);
      link.setAttribute("style", "display: none");
      document.body.appendChild(link);
      link.click();
    })
    .catch((reason) => {
      const axiosError = reason as AxiosError;
      new Blob([axiosError.response?.data as BlobPart]).text().then((text) => error(text));
    });

export default exportMissionsData;
