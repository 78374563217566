import { HelpOutline } from "@mui/icons-material";
import { MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { FormField, MissionDetailValue } from "../../data/types/Type";
import YesNoSelector from "./YesNoSelector";
import NumberTextField from "./NumberTextField";
import CheckboxList from "./CheckboxList";
import styles from "../../styles/form.module.scss";

interface FormRowProps {
  field: FormField;
  value?: MissionDetailValue;
  updateValue: (value: MissionDetailValue) => void;
}

const FormRow: FC<FormRowProps> = ({ field, value, updateValue }) => {
  let rightPart = (
    <TextField
      className={styles.form__notFullWidth}
      value={value || ""}
      onChange={(e) => updateValue(e.target.value)}
    />
  );
  switch (field.type) {
    case "boolean":
      rightPart = <YesNoSelector value={value as boolean} onChange={(val) => updateValue(val)} />;
      break;
    case "numeric":
    case "numeric-with-unit":
      rightPart = (
        <span>
          <NumberTextField
            className={styles.form__notFullWidth}
            value={(value as string) || ""}
            onChange={(val) => updateValue(val)}
            integersOnly
          />
          {field.type === "numeric-with-unit" ? <Typography>{field.unit}</Typography> : undefined}
        </span>
      );
      break;
    case "checkbox":
      rightPart = (
        <CheckboxList
          items={field.items || []}
          value={(value as number[]) || []}
          onChange={(val) => updateValue(val)}
        />
      );
      break;
    case "select":
      rightPart = (
        <Select
          className={styles.form__notFullWidth}
          value={(value as string) || ""}
          onChange={(event: SelectChangeEvent) => updateValue(parseInt(event.target.value, 10))}>
          {field.items?.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.label}
            </MenuItem>
          ))}
        </Select>
      );
      break;
    default:
      // Nothing, it already is the default rendering (TextField)
      break;
  }
  return (
    <div className={styles.form__row}>
      <div className={styles.form__fieldTitle}>
        <Typography>{field.name}</Typography>
        {field.description.length > 0 ? (
          <Tooltip title={field.description} placement="top" arrow>
            <HelpOutline />
          </Tooltip>
        ) : undefined}
      </div>
      <div className={styles.form__fieldControl}>{rightPart}</div>
    </div>
  );
};

export default FormRow;
