import { FC, Fragment, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import Header from "../common/Header";
import styles from "../../styles/missionDetails.module.scss";
import { DataContext } from "../../data/contexts/DataContext";
import { Typography } from "@mui/material";
import Animation, { Animations } from "../common/Animation";
import { dateToString, getAddressFromLocation, isStringEmpty } from "../../data/utils/Utils";
import FormRowReadOnly from "../forms/FormRowReadOnly";
import dayjs from "dayjs";

interface RouteParams {
  missionId?: string;
}

const getCommentBody = (body: string): string | React.JSX.Element | React.JSX.Element[] =>
  parse(body.replaceAll("<p>", "<span>").replaceAll("</p>", "</span>"));

const MissionDetails: FC = () => {
  const { missionId } = useParams() as RouteParams;
  const { getMissionDetails, missions, isLaunching } = useContext(DataContext);
  useEffect(() => {
    if (!isStringEmpty(missionId)) getMissionDetails(missionId as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionId]);

  const mission = missions.find((m) => m.id === parseInt(missionId || "-1")) || null;

  return (
    <div className={styles.container}>
      <Header title="Feuille de mission" />
      {isLaunching ? (
        <Animation animation={Animations.LOADER} maxWidth={300} maxHeight={300} />
      ) : mission === null ? (
        <Typography variant="h5">Mission introuvable</Typography>
      ) : (
        <div>
          {mission.realDate ? (
            <Typography className={styles.topDate}>Date : {dayjs(mission.realDate).format("DD/MM/YYYY")}</Typography>
          ) : (
            <Typography className={styles.topDate}>Date prévisionnelle : {mission.date}</Typography>
          )}
          <div className={styles.missionRoot}>
            {!isStringEmpty(mission.prescriptor) ? (
              <>
                <Typography className={styles.section}>Prescripteur</Typography>
                <Typography className={styles.info}>{mission.prescriptor}</Typography>
              </>
            ) : undefined}
            <Typography className={styles.section}>Client</Typography>
            <Typography className={styles.info}>Société : {mission.contact.company?.name}</Typography>
            <Typography className={styles.info}>
              Contact : {!mission.contact.firstName && !mission.contact.lastName ? "Pas de contact associé" : ""}
              {mission.contact.firstName} {mission.contact.lastName}
            </Typography>
            <Typography className={styles.info}>
              Fonction : {mission.contact.role || "Pas de contact associé"}
            </Typography>
            <Typography className={styles.info}>
              Adresse : {getAddressFromLocation(mission.contact.company?.location)}
            </Typography>
            <Typography className={styles.section}>Intervention</Typography>
            <Typography className={styles.info}>
              Type de prestation : <span className={styles.underline}>{mission.missionType}</span>
            </Typography>
            <Typography className={styles.info}>
              Adresse du lieu d&apos;intervention :{" "}
              <span className={styles.underline}>{getAddressFromLocation(mission.missionLocation)}</span>
            </Typography>
            <Typography className={styles.info}>
              Latitude, Longitude : {mission.missionGpsCoordinates?.lat}, {mission.missionGpsCoordinates?.lon}
            </Typography>
            <Typography className={styles.info}>
              Contact sur place :{" "}
              {isStringEmpty(mission.missionContact?.name) ? "" : `${mission.missionContact?.name} - `}
              {mission.missionContact?.phone}
            </Typography>
            {mission.missionFields?.missionType.length > 0 ? (
              <>
                <Typography className={styles.section}>Modalités</Typography>
                {mission.missionFields?.missionType.map((mt) => (
                  <FormRowReadOnly key={mt.id} field={mt} className={styles.info} />
                ))}
              </>
            ) : undefined}
            {mission.missionFields?.constraints.length > 0 ? (
              <>
                <Typography className={styles.section}>Contraintes</Typography>
                {mission.missionFields?.constraints.map((mt) => (
                  <FormRowReadOnly key={mt.id} field={mt} className={styles.info} />
                ))}
              </>
            ) : undefined}
            {mission.otherFields?.map((of) => (
              <Fragment key={of.id}>
                <Typography className={styles.section}>{of.name}</Typography>
                {of.fields.map((oft) => (
                  <FormRowReadOnly key={oft.id} field={oft} className={styles.info} />
                ))}
              </Fragment>
            ))}
            {mission.estimateNumber ? (
              <>
                <Typography className={styles.section}>Finance</Typography>
                <Typography className={styles.info}>
                  <a
                    href={`https://www.sellsy.com/?_f=estimateOverview&id=${mission.id}`}
                    target="_blank"
                    rel="noreferrer">
                    Devis : N°{mission.estimateNumber}
                  </a>
                </Typography>
              </>
            ) : undefined}
            <Typography className={styles.section}>Notes</Typography>
            <Typography className={styles.info}>
              {mission.notes?.map((c) => {
                return (
                  <span key={c.id} className={styles.noteContainer}>
                    <span className={styles.noteDate}>{dateToString(new Date(c.created))}</span>
                    {getCommentBody(c.description)}
                  </span>
                );
              }) || "N/C"}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default MissionDetails;
