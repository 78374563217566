import { Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Animation, { Animations } from "../common/Animation";
import Header from "../common/Header";
import { DataContext } from "../../data/contexts/DataContext";
import { AuthForm } from "./AuthModal";
import styles from "../../styles/authentication.module.scss";

interface RouteParams {
  userId?: string;
  correlationId?: string;
}

const Authentication: FC = () => {
  const { hasError } = useContext(DataContext);
  const [sent, setSent] = useState(false);
  const p = useParams() as RouteParams;
  const finalError = hasError || !p.userId || !p.correlationId;
  return (
    <div className={styles.authenticationContainer}>
      <Header />
      {finalError ? (
        <div className={styles.errorContainer}>
          {sent ? undefined : (
            <Typography variant="h6" className={styles.error}>
              Ce lien n&apos;est plus valide.
              <br />
              Pour effectuer une réservation, veuillez compléter le formulaire ci-dessous.
            </Typography>
          )}
          <div className={styles.authFormContainer}>
            <AuthForm onSend={() => setSent(true)} />
          </div>
        </div>
      ) : (
        <Animation animation={Animations.LOADER} />
      )}
    </div>
  );
};

export default Authentication;
