import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { Location } from "../../data/types/Type";
import styles from "../../styles/form.module.scss";
import { countries } from "../../data/utils/Utils";

export const defaultLocation: Location = {
  address: "",
  zipCode: "",
  city: "",
  countryCode: "FR",
};

interface LocationFormProps {
  title: string;
  location: Location;
  setLocation: (location: Location) => void;
  isOptional?: boolean;
}

const LocationForm: FC<LocationFormProps> = ({ title, location, setLocation, isOptional }) => {
  const handleChange = (event: SelectChangeEvent): void => {
    setLocation({ ...location, countryCode: event.target.value });
  };
  return (
    <div className={`${styles.location__form} ${isOptional ? styles.form__optionalLocation : ""}`}>
      <Typography className={styles.location__form__title}>{title}</Typography>
      <div className={styles.form__group}>
        <TextField
          label="Adresse"
          value={location.address}
          onChange={(e) => setLocation({ ...location, address: e.target.value })}
          className={styles.location__form__address}
        />
        <TextField
          label="Code postal"
          value={location.zipCode}
          onChange={(e) => setLocation({ ...location, zipCode: e.target.value })}
          className={styles.location__form__zipCode}
          inputProps={{ maxLength: 5 }}
        />
        <TextField
          label="Ville"
          value={location.city}
          onChange={(e) => setLocation({ ...location, city: e.target.value })}
          className={styles.location__form__city}
        />
        <FormControl className={styles.location__form__country}>
          <InputLabel id="select-label">Pays</InputLabel>
          <Select labelId="dselect-label" value={location.countryCode} label="Pays" onChange={handleChange}>
            {countries?.map((c) => (
              <MenuItem key={c.value} value={c.value}>
                {c.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default LocationForm;
