import dayjs, { Dayjs } from "dayjs";
import { Country, LightMission, Location, MissionsSpot } from "../types/Type";

export const getItemFromLocalStorage = <T>(storeName: string): T | null => {
  const result = localStorage.getItem(storeName);
  if (result !== null) return JSON.parse(result) as T;
  return null;
};

export const dateToString = (d: Date): string =>
  `${d.getDate().toString().padStart(2, "0")}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${d.getFullYear()}`;

/** Gets a week from a date according to a template (ex: "du {{start}} au {{end}}"). */
export const getWeekFromDate = (d: string, template?: string): string => {
  const finalTemplate = template || "du {{start}} au {{end}}";
  const date = new Date(d);
  const endOfWeek = new Date(date.getTime() + 5 * 24 * 3600 * 1000);
  return finalTemplate.replace("{{start}}", dateToString(date)).replace("{{end}}", dateToString(endOfWeek));
};

const getMondayFromDate = (date: string): Date => {
  // We get the day before the monday of date's week because it simplifies comparison in filter
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -7 : 0); // adjust when day is sunday
  d.setDate(diff);
  return d;
};

export const isIFrameMode = (): boolean => window.location.href.indexOf("iframeMode") !== -1;

export const isFutureTimestamp = (timestamp?: number, marginSeconds = 10): boolean =>
  typeof timestamp === "number" && timestamp > new Date().getTime() / 1000 + marginSeconds;

export const isEmailValid = (email?: string): boolean =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ) !== null;

export const isStringEmpty = (s?: string): boolean => (s?.length || 0) === 0;

export const isLocationValid = (l?: Location, isOptional = false): boolean =>
  isOptional ||
  (!isStringEmpty(l?.address) &&
    !isStringEmpty(l?.zipCode) &&
    !isStringEmpty(l?.city) &&
    !isStringEmpty(l?.countryCode));

export const countries: Country[] = [
  { value: "FR", label: "France" },
  { value: "BE", label: "Belgique" },
];

export const getCountryName = (code?: string): string =>
  (typeof code === "string" && countries.find((c) => c.value === code)?.label) || "";

export const getMarkerClassName = (mission: LightMission): string => {
  const today = Date.now();
  let className = "markerMission gray";
  if (!mission.realDate && mission.date) className = "markerMission orange";
  if (mission.realDate) {
    if (new Date(mission.realDate).getTime() > today) className = "markerMission green";
  }
  return className;
};

export const getDateFromDayJs = (d?: Dayjs | null): string => (d ? d.toISOString().split("T")[0] : "");

const isNullOrUndefined = (d?: Dayjs | null): boolean => d === null || typeof d === "undefined";
const isDateEmptyOrAbove = (base: string, compared?: Dayjs | null): boolean =>
  isNullOrUndefined(compared) || dayjs(base) <= (compared as Dayjs);
const isDateEmptyOrBelow = (base: string, compared?: Dayjs | null): boolean =>
  isNullOrUndefined(compared) || dayjs(base) >= (compared as Dayjs);
const datesFilter = (base?: string, startDate?: Dayjs | null, endDate?: Dayjs | null): boolean =>
  isStringEmpty(base) || (isDateEmptyOrBelow(base as string, startDate) && isDateEmptyOrAbove(base as string, endDate));

export const getFilteredMissions = (
  missions: LightMission[],
  startDate?: Dayjs | null,
  endDate?: Dayjs | null,
): LightMission[] => missions.filter((m) => datesFilter(m.realDate, startDate, endDate));

export const getFilteredMissionsSpots = (
  missionsSpots: MissionsSpot[],
  startDate?: Dayjs | null,
  endDate?: Dayjs | null,
): MissionsSpot[] => {
  // StartDate should be the monday of the week for non-admins
  const finalStartDate = dayjs(getMondayFromDate(startDate?.toISOString()?.split("T")?.[0] || ""));
  return missionsSpots.filter((m) => datesFilter(m.date, finalStartDate, endDate));
};

export enum AppSections {
  AUTH = "AUTH",
  HOME = "HOME",
  RESERVATION_FORM = "RESERVATION_FORM",
  MISSION_DETAILS = "MISSION_DETAILS",
}

export const getCurrentAppSection = (): AppSections => {
  const { pathname } = window.location;
  if (pathname.startsWith("/auth/")) return AppSections.AUTH;
  if (pathname.startsWith("/mission/")) return AppSections.MISSION_DETAILS;
  if (pathname.startsWith("/reservation")) return AppSections.RESERVATION_FORM;
  return AppSections.HOME;
};

export const getAddressFromLocation = (l?: Location, hideCountry = false): string => {
  if (!l || (l.address.length === 0 && l.zipCode.length === 0 && l.city.length === 0 && l.countryCode.length === 0))
    return "N/C";
  const countryName = getCountryName(l.countryCode);
  return `${l.address.length > 0 ? `${l.address}, ` : ""}${l.zipCode} ${l.city}${
    countryName && !hideCountry ? ` - ${countryName}` : ""
  }`;
};
