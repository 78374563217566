import dayjs, { Dayjs } from "dayjs";
import { useSnackbar } from "notistack";
import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react";
import { authenticationWithMagicLink, getMagicLink, getMission, getMissions, patchMission } from "../Api";
import {
  Auth,
  LOCAL_STORAGE_STORE,
  Mission,
  MissionCreation,
  MissionType,
  MissionsSpot,
  Person,
  Tokens,
} from "../types/Type";
import { AppSections, getCurrentAppSection, getItemFromLocalStorage, isFutureTimestamp } from "../utils/Utils";
import { AuthContext } from "./AuthContext";
import exportMissionsData from "../utils/Export";

const anonymousPerson = { id: "anonyous", email: "", admin: false };

export const DataContext = createContext<{
  sendEmail: (email: string) => Promise<number>;
  isAnonymous: boolean;
  person: Person;
  isAdmin: boolean;
  saveReservation: (mission: Partial<MissionCreation>, step: number) => Promise<number>;
  missionTypes: MissionType[] | undefined;
  logOut: () => void;
  missionCreation: Partial<MissionCreation> | undefined;
  hasError: boolean;
  getMissionTypeName: (id: string) => string;
  getMissionDetails: (missionId: string, tokenToUse?: string) => Promise<Mission | null>;
  getMissionsList: (resetCache?: boolean, tokenToUse?: string) => Promise<number>;
  isMissionLoading: boolean;
  missions: Mission[];
  missionsSpots: MissionsSpot[];
  startDate: Dayjs | null | undefined;
  setStartDate: (date?: Dayjs | null) => void;
  endDate: Dayjs | null;
  setEndDate: (date: Dayjs | null) => void;
  exportData: () => Promise<void>;
  isLaunching: boolean;
}>({
  sendEmail: () => Promise.resolve(0),
  isAnonymous: true,
  person: anonymousPerson,
  isAdmin: false,
  saveReservation: () => Promise.resolve(0),
  missionTypes: [],
  logOut: () => {},
  missionCreation: undefined,
  hasError: false,
  getMissionTypeName: () => "",
  getMissionDetails: () => Promise.resolve(null),
  getMissionsList: () => Promise.resolve(0),
  isMissionLoading: false,
  missions: [],
  missionsSpots: [],
  startDate: undefined,
  setStartDate: () => {},
  endDate: null,
  setEndDate: () => {},
  exportData: () => Promise.resolve(),
  isLaunching: false,
});

const personToMissionCreation = (auth: Person): Partial<MissionCreation> => ({
  contactId: auth.sellsyId,
  firstName: auth.firstName,
  lastName: auth.lastName,
  contactLocation: auth.location,
  companyName: auth.company?.name || "",
  companyLocation: auth.company?.location,
  billingLocation: auth.company?.billingLocation,
  newsletter: auth.newsletter,
  phone: auth.phone,
  role: auth.role,
});

const DataContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [person, setPerson] = useState<Person>(anonymousPerson);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [missionTypes, setMissionTypes] = useState<MissionType[] | undefined>([]);
  const [missionCreation, setMissionCreation] = useState<Partial<MissionCreation>>();
  const [missions, setMissions] = useState<Mission[]>([]);
  const [latestDetailsFetch, setLatestDetailsFetch] = useState<Record<string, number>>({});
  const [missionsSpots, setMissionsSpots] = useState<MissionsSpot[]>([]);
  const [isMissionLoading, setIsMissionLoading] = useState<boolean>(false);
  const { auth, wipeAuth, getToken, handleTokens, refreshToken, initLocalAuth } = useContext(AuthContext);
  const today = dayjs();
  const firstDayOfCurrentYear = dayjs(new Date(new Date().getFullYear(), 0, 1));
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>(undefined);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [isLaunching, setIsLaunching] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const validateMagicLink = async (): Promise<void> => {
    const urlParts = window.location.pathname.split("/");
    const res = await magicLinkAuthentication(urlParts[2], urlParts[3]);
    if (res === 400) setHasError(true);
    else if (typeof res === "number")
      enqueueSnackbar("Une erreur est survenue, veuillez réessayer plus tard", { variant: "error" });
    else window.location.pathname = res.auth.admin === true ? "/" : "/reservation";
  };

  const initMissionDetails = async (token: string): Promise<void> => {
    const { pathname } = window.location;
    const missionId = pathname.split("/")[2];
    await getMissionDetails(missionId, token);
  };

  useEffect(() => {
    // Launch sequence
    const localAuth = initLocalAuth();
    (async () => {
      // Get local data and load it
      const personData = getItemFromLocalStorage<Person>(LOCAL_STORAGE_STORE.USER_STORE);
      const missionTypesData = getItemFromLocalStorage<MissionType[]>(LOCAL_STORAGE_STORE.MISSION_TYPES_STORE);
      if (personData) storeAuthData({ auth: personData, missionTypes: missionTypesData || [] });
      else storeAuthData(null);
      // Refresh token if necessary
      let token = localAuth?.access_token;
      if (localAuth !== null && !isFutureTimestamp(localAuth.accessExpires)) {
        const newAuth = await refreshToken(localAuth);
        storeAuthData(newAuth);
        token = newAuth?.access_token;
      }
      // Fetch missions
      getMissionsList(false, token, personData?.admin);
      const section = getCurrentAppSection();
      if (section === AppSections.AUTH) await validateMagicLink();
      if (section === AppSections.MISSION_DETAILS) await initMissionDetails(token || "");
      setIsLaunching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAdmin = useCallback(
    (admin?: boolean): void => {
      setIsAdmin(admin || false);
      if (admin) setStartDate(firstDayOfCurrentYear);
      else setStartDate(today);
    },
    [firstDayOfCurrentYear, today],
  );

  const storeAuthData = useCallback(
    (newAuth?: Auth | null): void => {
      if (!newAuth) {
        changeAdmin(false);
        setPerson(anonymousPerson);
        setMissionCreation({});
        setMissionTypes([]);
      } else {
        localStorage.setItem(LOCAL_STORAGE_STORE.USER_STORE, JSON.stringify(newAuth.auth));
        localStorage.setItem(LOCAL_STORAGE_STORE.MISSION_TYPES_STORE, JSON.stringify(newAuth.missionTypes));
        setPerson(newAuth.auth);
        changeAdmin(newAuth.auth.admin || false);
        setMissionCreation({ ...missionCreation, ...personToMissionCreation(newAuth.auth) });
        setMissionTypes(newAuth.missionTypes);
      }
    },
    [missionCreation, changeAdmin],
  );

  const wipeData = (): void => {
    changeAdmin(false);
    setPerson(anonymousPerson);
    setMissionCreation({});
    setMissionTypes([]);
    localStorage.removeItem(LOCAL_STORAGE_STORE.MISSION_TYPES_STORE);
    localStorage.removeItem(LOCAL_STORAGE_STORE.USER_STORE);
  };

  const sendEmail = async (email: string): Promise<number> => {
    const result = await getMagicLink(email);

    if (typeof result === "string") {
      return 200;
    }
    return result;
  };

  const magicLinkAuthentication = async (userId: string, correlationId: string): Promise<number | Auth> => {
    try {
      const result = await authenticationWithMagicLink(`${userId}/${correlationId}`);
      if (typeof result !== "number") {
        storeAuthData(result);
        handleTokens(result as Tokens);
      }
      return result;
    } catch (e) {
      console.error(e);
      return 500;
    }
  };

  const fetchWithToken = async function <T>(
    fetchAction: (token: string) => Promise<T | number>,
    isTokenMandatory = true,
    tokenToUse?: string,
  ): Promise<T | number> {
    try {
      const token = tokenToUse || (await getToken(isTokenMandatory));
      if (token || !isTokenMandatory) {
        const result = await fetchAction(token || "");
        if (typeof result === "object") {
          return result as T;
        }
        return result;
      } else {
        // Refresh token expired, user is no longer authenticated
        wipeData();
        return 200;
      }
    } catch (e) {
      console.error(e);
      return 500;
    }
  };

  const saveReservation = async (mission: Partial<MissionCreation>, step: number): Promise<number> => {
    const result = await fetchWithToken<MissionCreation>((token: string) =>
      patchMission({ ...missionCreation, ...mission }, `${step}`, token),
    );
    if (typeof result === "number") {
      // No output, store the input...
      setMissionCreation({ ...mission, ...missionCreation });
      return result;
    }
    setMissionCreation(result);
    return 200;
  };

  const logOut = (): void => {
    wipeData();
    wipeAuth();
    getMissionsList(undefined, undefined, undefined, true);
  };

  const getMissionTypeName = (id: string): string => missionTypes?.find((mt) => mt.id === id)?.name || "";

  const isAnonymous = auth === null || !isFutureTimestamp(auth?.refreshExpires);

  const mergeMissions = (incomingMissions: Mission[]): void => {
    setMissions((prev) => {
      const newMissions = prev.map((m) => {
        const incomingMission = incomingMissions.find((im) => im.id === m.id);
        if (incomingMission) return { ...m, ...incomingMission };
        return m;
      });
      incomingMissions.forEach((im) => {
        if (newMissions.findIndex((nm) => nm.id === im.id) === -1) newMissions.push(im);
      });
      return newMissions;
    });
  };

  const getMissionDetails = async (missionId: string, tokenToUse?: string): Promise<Mission | null> => {
    const local = missions.find((m) => m.id === parseInt(missionId));
    const latestFetch = latestDetailsFetch[missionId];
    // Cache on details is 60s
    if (isMissionLoading || (local && latestFetch < new Date().getTime() + 60000)) return local || null;
    setIsMissionLoading(true);
    const result = await fetchWithToken<Mission>((token: string) => getMission(missionId, token), true, tokenToUse);
    if (typeof result === "number") {
      // Fetch failed
      setIsMissionLoading(false);
      return null;
    }
    mergeMissions([result]);
    setIsMissionLoading(false);
    setLatestDetailsFetch({ ...latestDetailsFetch, [missionId]: new Date().getTime() });
    return result;
  };

  const getMissionsList = async (
    resetCache = false,
    tokenToUse?: string,
    initialIsAdmin?: boolean,
    forceSpots = false,
  ): Promise<number> => {
    if (
      !resetCache &&
      ((missions.length !== 0 && isAdmin && !forceSpots) || (missionsSpots.length !== 0 && !isAdmin && !forceSpots))
    )
      return 200;
    setIsMissionLoading(true);
    const result = await fetchWithToken<Mission[]>(
      (token: string) => getMissions(forceSpots ? "" : token, resetCache),
      false,
      tokenToUse,
    );
    if (typeof result === "number") {
      // Fetch failed
      setIsMissionLoading(false);
      return result;
    }
    if ((isAdmin || initialIsAdmin) && !forceSpots) mergeMissions(result);
    else setMissionsSpots(result); // If user is not an admin the route returns spots, not missions
    setIsMissionLoading(false);
    return 200;
  };

  const exportData = async (): Promise<void> => {
    await fetchWithToken<void>(
      (token: string) => exportMissionsData(token, (error) => enqueueSnackbar(error, { variant: "error" })),
      true,
    );
  };

  return (
    <DataContext.Provider
      value={{
        sendEmail,
        isAnonymous,
        person,
        isAdmin,
        saveReservation,
        missionTypes,
        logOut,
        missionCreation,
        hasError,
        getMissionTypeName,
        getMissionDetails,
        getMissionsList,
        isMissionLoading,
        missions,
        missionsSpots,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        exportData,
        isLaunching,
      }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
