import { AccountCircle, Cached } from "@mui/icons-material";
import { CircularProgress, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { FC, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../data/contexts/DataContext";
import { isIFrameMode } from "../../data/utils/Utils";
import styles from "../../styles/header.module.scss";
import Profile from "../auth/Profile";
import Button from "./Button";
import { log } from "../../data/Logger";

interface DateFieldProps {
  prefix: string;
  isStartDate: boolean;
  isAdmin: boolean;
}

const DateField: FC<DateFieldProps> = ({ prefix, isAdmin, isStartDate }) => {
  const { setEndDate, setStartDate, startDate } = useContext(DataContext);
  const setValue = isStartDate ? setStartDate : setEndDate;
  const today = dayjs();
  const minDate = !isStartDate ? startDate || (isAdmin ? undefined : today) : isAdmin ? undefined : today;

  log.debug("startDate from DateField", startDate);

  return useMemo(
    () => {
      log.debug("I rerender the Datefield", isStartDate, startDate);
      return (
        <div className={styles.reservationDates}>
          <Typography>{prefix}</Typography>
          <DatePicker
            defaultValue={isStartDate ? startDate : undefined}
            onChange={(v) => {
              if (v?.isValid() && (!minDate || v > minDate)) {
                log.debug(v, minDate);
                setValue(v);
              }
            }}
            minDate={minDate}
            slots={{
              textField: (p) => <TextField {...p} className={styles.reservationDate} />,
            }}
          />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [minDate, startDate],
  );
};

interface HeaderProps {
  reservationControls?: boolean;
  openProfile?: () => void;
  title?: string;
}

const Header: FC<HeaderProps> = ({ reservationControls = false, title = "" }) => {
  const { isAnonymous, isAdmin, getMissionsList, isMissionLoading, exportData, startDate } = useContext(DataContext);
  const [openProfile, setOpenProfile] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const fromIframe = isIFrameMode();
  const resetCache = async (): Promise<void> => {
    const res = await getMissionsList(true);
    if (res !== 200)
      enqueueSnackbar("Un problème est survenu lors de la récupération des données", { variant: "error" });
  };
  const getExport = async (): Promise<void> => {
    setIsExporting(true);
    await exportData();
    setIsExporting(false);
  };

  const navigate = useNavigate();
  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        {fromIframe ? undefined : (
          <img
            onClick={() => navigate("/")}
            src="/assets/agrodroneLogo.png"
            alt="Logo Agrodrone"
            className={styles.logo}
          />
        )}
        {reservationControls ? (
          <div className={styles.reservationControls}>
            <Typography className={styles.reservationDate}>Votre recherche :</Typography>
            {typeof startDate !== "undefined" ? (
              <div className={styles.reservationDatesContainer}>
                <DateField prefix="Du" isAdmin={isAdmin} isStartDate />
                <DateField prefix="Au" isAdmin={isAdmin} isStartDate={false} />
              </div>
            ) : undefined}
          </div>
        ) : undefined}
        {title ? <Typography variant="h5">{title}</Typography> : undefined}
      </div>
      {isAdmin ? (
        <Button
          label="Télécharger toutes les missions"
          onClick={getExport}
          isLoading={isExporting}
          className={styles.exportButton}
        />
      ) : undefined}
      <div className={styles.rightButtons}>
        {isAdmin ? (
          isMissionLoading ? (
            <span className={`${styles.resettingContainer} ${styles.resetButton}`}>
              <CircularProgress size={24} />
            </span>
          ) : (
            <Tooltip arrow title="Forcer la synchronisation avec Sellsy" className={styles.resetButton}>
              <IconButton onClick={resetCache}>
                <Cached />
              </IconButton>
            </Tooltip>
          )
        ) : undefined}
        {isAnonymous ? undefined : (
          <IconButton onClick={() => setOpenProfile(true)} className={styles.profileButton}>
            <AccountCircle />
          </IconButton>
        )}
      </div>
      <Profile open={openProfile} onClose={() => setOpenProfile(false)} />
    </div>
  );
};

export default Header;
