import { MyLocation } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import L, { LatLng, LatLngExpression, divIcon } from "leaflet";
import { OpenStreetMapProvider, SearchControl } from "leaflet-geosearch";
import { FC, useContext, useEffect, useState } from "react";
import { Circle, LayerGroup, LayersControl, Marker, Popup, TileLayer, ZoomControl, useMapEvents } from "react-leaflet";
import { DataContext } from "../../data/contexts/DataContext";
import {
  getFilteredMissions,
  getFilteredMissionsSpots,
  getMarkerClassName,
  getWeekFromDate,
} from "../../data/utils/Utils";
import styles from "../../styles/map.module.scss";
import Button from "../common/Button";
import PopupInfo from "../mission/PopupInfo";
import tilesLayer from "./tilesLayer.json";
import { LightMission } from "../../data/types/Type";
import { enqueueSnackbar } from "notistack";

interface MissionMarkerProps {
  mission: LightMission;
}

const MissionMarker: FC<MissionMarkerProps> = ({ mission }) => {
  const customMarkerIcon = divIcon({
    html: `<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path fill="#000000" fill-rule="evenodd" d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" clip-rule="evenodd"/>
          </svg>`,
    iconSize: new L.Point(30, 30),
    iconAnchor: [25, 45],
    className: getMarkerClassName(mission),
  });

  return (
    <Marker position={mission.missionGpsCoordinates as unknown as LatLngExpression} icon={customMarkerIcon}>
      <Popup offset={[0, -32]}>
        <PopupInfo data={mission} />
      </Popup>
    </Marker>
  );
};

export const osmProvider = new OpenStreetMapProvider({
  params: {
    "accept-language": "fr",
    countrycodes: "fr,be",
  },
});

const customMarkerIcon = divIcon({
  html: `<svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
          <path fill="#000000" fill-rule="evenodd" d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" clip-rule="evenodd"/>
        </svg>`,
  iconSize: new L.Point(30, 30),
  iconAnchor: [25, 45],
  // popupAnchor: [0, -45],
});

const customMyLocationIcon = divIcon({
  html: `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="#259a8e" fill-rule="nonzero">
          <path d="M12,2 C12.3796958,2 12.693491,2.28215388 12.7431534,2.64822944 L12.75,2.75 L12.7490685,4.53770881 L12.7490685,4.53770881 C16.292814,4.88757432 19.1124257,7.70718602 19.4632195,11.2525316 L19.5,11.25 L21.25,11.25 C21.6642136,11.25 22,11.5857864 22,12 C22,12.3796958 21.7178461,12.693491 21.3517706,12.7431534 L21.25,12.75 L19.4616558,12.7490368 L19.4616558,12.7490368 C19.1124257,16.292814 16.292814,19.1124257 12.7474684,19.4632195 L12.75,19.5 L12.75,21.25 C12.75,21.6642136 12.4142136,22 12,22 C11.6203042,22 11.306509,21.7178461 11.2568466,21.3517706 L11.25,21.25 L11.2509632,19.4616558 L11.2509632,19.4616558 C7.70718602,19.1124257 4.88757432,16.292814 4.53678051,12.7474684 L4.5,12.75 L2.75,12.75 C2.33578644,12.75 2,12.4142136 2,12 C2,11.6203042 2.28215388,11.306509 2.64822944,11.2568466 L2.75,11.25 L4.53770881,11.2509315 L4.53770881,11.2509315 C4.88757432,7.70718602 7.70718602,4.88757432 11.2525316,4.53678051 L11.25,4.5 L11.25,2.75 C11.25,2.33578644 11.5857864,2 12,2 Z M12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 Z M12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 Z">
</path>
      </g>
  </g>
</svg>`,
  iconSize: [30, 30],
  className: "geoloc-icon",
});

const SpotPopup: FC<{
  date: string;
  onReservationButtonClick?: () => void;
}> = ({ date, onReservationButtonClick }) => (
  <div className={styles.circlePopup}>
    <p style={{ margin: "0", fontWeight: "600" }}>
      Intervention
      <br />
      {getWeekFromDate(date)}
    </p>
    <Button
      onClick={onReservationButtonClick}
      className={styles.reservationButton}
      label="Réserver"
      color="secondary"
    />
  </div>
);

export interface MapProps {
  onReservationButtonClick?: () => void;
  defaultIsSatellite?: boolean;
  fromModal?: boolean;
  onClick?: (coords: LatLng) => void;
}

const MissionsMarkers: FC = () => {
  const { missions, startDate, endDate } = useContext(DataContext);
  return (
    <LayerGroup>
      {getFilteredMissions(missions, startDate, endDate).map((m, index) => (
        <MissionMarker mission={m} key={index} />
      ))}
    </LayerGroup>
  );
};

const MissionsSpots: FC<{ onReservationButtonClick?: () => void }> = ({ onReservationButtonClick }) => {
  const { missionsSpots, startDate, endDate } = useContext(DataContext);
  return (
    <LayerGroup>
      {getFilteredMissionsSpots(missionsSpots, startDate, endDate).map((spot, index) => (
        <Circle
          center={spot.center as unknown as LatLngExpression}
          radius={50000}
          fillColor="#259A8E"
          color="#259A8E"
          fillOpacity={0.2}
          key={index}>
          <Popup offset={[0, 20]}>
            <SpotPopup date={spot.date} onReservationButtonClick={onReservationButtonClick} />
          </Popup>
        </Circle>
      ))}
    </LayerGroup>
  );
};

export const defaultUserCoordinates = [46.3415466, 2.6019912];

const Map: FC<MapProps> = ({
  onReservationButtonClick,
  defaultIsSatellite = false,
  fromModal = false,
  onClick = undefined,
}) => {
  const [userCoordinates, setUserCoordinates] = useState<LatLngExpression>(defaultUserCoordinates as LatLngExpression);
  const [isGeoloc, setIsGeoLoc] = useState(false);
  const [clickCoords, setClickCoords] = useState<LatLng | undefined>();
  const { isAdmin } = useContext(DataContext);
  const isMobile = window.innerWidth < 780;

  const map = useMapEvents({
    click(e) {
      if (typeof onClick !== "undefined") {
        onClick(e.latlng);
        setClickCoords(e.latlng);
      }
    },
    locationfound(e) {
      setUserCoordinates(e.latlng);
      if (!fromModal && !isAdmin) map.flyTo(e.latlng, map.getZoom());
    },
  });

  useEffect((): (() => void) => {
    if (!fromModal) map.locate();
    const searchControl = SearchControl({
      style: "bar",
      provider: osmProvider,
      showMarker: true,
      autoComplete: true,
      autoCompleteDelay: 250,
      searchLabel: "Entrez une adresse",
      marker: {
        icon: customMarkerIcon,
      },
    });
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <>
      <Tooltip
        title="Me géolocaliser"
        arrow
        onClick={(e) => {
          e.preventDefault();
          if (userCoordinates === defaultUserCoordinates) {
            enqueueSnackbar("Vous avez refusé le partage de vos coordonnées", { variant: "error" });
          } else {
            setIsGeoLoc(true);
          }
          map.flyTo(userCoordinates, isMobile ? 5 : 6);
        }}>
        <MyLocation className={styles.myLocation} />
      </Tooltip>
      <ZoomControl position="topleft" />
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked={!defaultIsSatellite} name="Carte">
          <TileLayer attribution={tilesLayer.openStreetMap.attribution} url={tilesLayer.openStreetMap.url} />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked={defaultIsSatellite} name="Satellite">
          <TileLayer attribution={tilesLayer.geoPortail.attribution} url={tilesLayer.geoPortail.url} />
        </LayersControl.BaseLayer>
      </LayersControl>

      {isGeoloc && <Marker position={userCoordinates} icon={customMyLocationIcon} />}

      {clickCoords ? (
        <>
          <Marker position={clickCoords} icon={customMarkerIcon} key="clickCoords"></Marker>
          <Popup position={clickCoords} offset={[0, -32]}>
            <Typography className={styles.clickConfirm}>
              Cette position ({clickCoords.lng.toFixed(6)}, {clickCoords.lat.toFixed(6)}) a été copiée. Vous pouvez
              fermer cette fenêtre ou choisir un autre point
            </Typography>
          </Popup>
        </>
      ) : undefined}

      {fromModal ? undefined : isAdmin ? (
        <MissionsMarkers />
      ) : (
        <MissionsSpots onReservationButtonClick={onReservationButtonClick} />
      )}
    </>
  );
};

export default Map;
