import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { LightMission } from "../../data/types/Type";
import Button from "../common/Button";
import { getAddressFromLocation, isStringEmpty } from "../../data/utils/Utils";
import styles from "../../styles/popupInfo.module.scss";

export interface PopupData {
  name: string;
  date: string;
  interventionType?: string;
  info?: string;
  obstacles?: string;
  equipment?: string;
  pilot?: string;
  flyByLink?: string;
  myMapsLink?: string;
  sellsyQuote?: string;
}

interface PopupInfoProps {
  data: LightMission;
}

const PopupInfo: FC<PopupInfoProps> = ({ data }) => {
  const { contact, date, missionType, realDate, flyByLink, myMapsLink, id, missionLocation } = data;
  const navigate = useNavigate();
  return (
    <div className={styles.admin_popup}>
      {!isStringEmpty(realDate) ? (
        <div className={`${styles.admin_popup_row} ${styles.bold}`}>
          <span className={styles.admin_popup_row_static}>Date :</span>
          <span className={styles.admin_popup_row_dynamic}>{dayjs(realDate).format("DD/MM/YYYY")}</span>
        </div>
      ) : !isStringEmpty(date) ? (
        <div className={`${styles.admin_popup_row} ${styles.bold}`}>
          <span className={styles.admin_popup_row_static}>Date prévisionnelle :</span>
          <span className={styles.admin_popup_row_dynamic}>{date}</span>
        </div>
      ) : undefined}
      <div className={styles.admin_popup_row}>
        <span className={styles.admin_popup_row_static}>Client :</span>
        <span className={styles.admin_popup_row_dynamic}>{contact.company?.name}</span>
      </div>
      {!isStringEmpty(missionLocation?.countryCode) && (
        <div className={styles.admin_popup_row}>
          <span className={styles.admin_popup_row_dynamic}>{getAddressFromLocation(missionLocation, true)}</span>
        </div>
      )}
      {!isStringEmpty(missionType) && (
        <div className={`${styles.admin_popup_row} ${styles.marginVertical}`}>
          <span className={styles.admin_popup_row_dynamic}>{missionType}</span>
        </div>
      )}

      <div className={`${styles.admin_popup_row} ${styles.link}`}>
        {flyByLink && (
          <a className={styles.admin_popup_link} href={flyByLink} target="_blank" rel="noreferrer">
            Flyby
          </a>
        )}
        {myMapsLink && (
          <a className={styles.admin_popup_link} href={myMapsLink} target="_blank" rel="noreferrer">
            MyGoogle
          </a>
        )}
      </div>
      <Button
        label="Voir la feuille de mission"
        color="secondary"
        size="small"
        onClick={() => navigate(`/mission/${id}`)}
      />
    </div>
  );
};
export default PopupInfo;
