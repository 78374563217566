import { Typography } from "@mui/material";
import { FC } from "react";
import { FormField, MissionDetailAmountCurrencyValue, MissionDetailNumberUnitValue } from "../../data/types/Type";

interface FormRowReadOnlyProps {
  field: FormField;
  className?: string;
}

const emptyValue = "N/C";

const FormRowReadOnly: FC<FormRowReadOnlyProps> = ({ field, className }) => {
  const { value } = field;
  let rightPart: string | JSX.Element = (value as string) || "";
  if (
    typeof value === "undefined" ||
    value === "" ||
    value === null ||
    (field.type === "select" && value === 0) ||
    (value as MissionDetailNumberUnitValue).number === null ||
    (value as MissionDetailAmountCurrencyValue).amount === null
  )
    rightPart = emptyValue;
  else {
    switch (field.type) {
      case "boolean":
        rightPart = value === true ? "Oui" : value === false ? "Non" : emptyValue;
        break;
      case "numeric-with-unit":
        rightPart = (value as MissionDetailNumberUnitValue).number;
        break;
      case "amount":
        rightPart = `${(value as MissionDetailAmountCurrencyValue).amount} ${
          (value as MissionDetailAmountCurrencyValue).currency
        }`;
        break;
      case "checkbox":
        rightPart =
          field.items
            ?.filter((i) => (value as number[])?.includes(i.id))
            ?.map((i) => i.label)
            .join(", ") || emptyValue;
        break;
      case "radio":
      case "select":
        rightPart = field.items?.find((i) => i.id === value)?.label || emptyValue;
        break;
      case "url":
        rightPart = (
          <a href={value as string} target="_blank" rel="noreferrer">
            {value as string}
          </a>
        );
        break;
      default:
        // Nothing, it already is the default rendering (just the value)
        break;
    }
  }
  return (
    <Typography className={className}>
      {field.name} : {rightPart}
    </Typography>
  );
};

export default FormRowReadOnly;
