import { MapContainer } from "react-leaflet";
import { FC, useContext } from "react";
import { Typography } from "@mui/material";
import Map, { MapProps } from "./Map";
import Button from "../common/Button";
import Header from "../common/Header";
import styles from "../../styles/map.module.scss";
import { DataContext } from "../../data/contexts/DataContext";

const Container: FC<MapProps> = ({ onReservationButtonClick, defaultIsSatellite }) => {
  const { isAdmin } = useContext(DataContext);
  const isMobile = window.innerWidth < 780;
  return (
    <div className={styles.mapScreenRoot}>
      <Header reservationControls />
      <MapContainer
        className={styles.mapContainer}
        center={[46.3415466, 2.6019912]}
        zoom={isMobile ? 5 : 6}
        scrollWheelZoom
        doubleClickZoom
        zoomControl={false}
        maxZoom={18}>
        <Map onReservationButtonClick={onReservationButtonClick} defaultIsSatellite={defaultIsSatellite} />
      </MapContainer>
      {isAdmin ? undefined : (
        <div className={styles.hint}>
          <Typography>
            Nous ne sommes pas dans votre secteur ?<span className={styles.secondHint}> Demandez-nous de venir !</span>
          </Typography>
          <Button label="Demande de devis" onClick={onReservationButtonClick} />
        </div>
      )}
    </div>
  );
};

export default Container;
