import { Button, Modal, Paper, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { DataContext } from "../../data/contexts/DataContext";
import styles from "../../styles/profile.module.scss";
import { getCountryName } from "../../data/utils/Utils";

interface ProfileProps {
  open: boolean;
  onClose: () => void;
}

const Profile: FC<ProfileProps> = ({ open, onClose }) => {
  const { person, logOut } = useContext(DataContext);
  const { firstName, lastName, email, company } = person;

  return (
    <Modal open={open} onClose={onClose} className="modal-root">
      <Paper className="modal-paper">
        <div className={styles.profileModal}>
          <Typography className={styles.title}>Votre profil</Typography>
          <div className={styles.profile}>
            <div className={styles.person}>
              <Typography className={styles.partTitle}>Utilisateur :</Typography>
              <div className={styles.part}>
                <Typography>{firstName}</Typography>
                <Typography>{lastName}</Typography>
              </div>
              <Typography>{email}</Typography>
            </div>
            <div className={styles.company}>
              <Typography className={styles.partTitle}>Société :</Typography>
              <Typography>{company?.name}</Typography>
              <Typography>{company?.location?.address}</Typography>
              <Typography>{company?.location?.zipCode}</Typography>
              <Typography>{company?.location?.city}</Typography>
              <Typography>{getCountryName(company?.location?.countryCode)}</Typography>
            </div>
          </div>
          <Button
            sx={{
              background: "#259a8e",
              color: "#fff",
              "&:hover": {
                background: "#259a8e",
              },
            }}
            onClick={() => {
              logOut();
              onClose();
            }}>
            Se déconnecter
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default Profile;
