import { Close } from "@mui/icons-material";
import { IconButton, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { FC, createRef } from "react";
import "dayjs/locale/fr";
import { AuthProvider } from "../data/contexts/AuthContext";
import DataContextProvider from "../data/contexts/DataContext";
import TabsContextProvider from "../data/contexts/TabsContext";
import Theme from "./Theme";
import App from "../components/App";

const notistackRef = createRef<SnackbarProvider>();

const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

const SnackBarCloser = (key: SnackbarKey): JSX.Element => (
  <IconButton onClick={onClickDismiss(key)} sx={{ color: "#fff" }}>
    <Close />
  </IconButton>
);

const Wrapper: FC = () => {
  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      preventDuplicate
      autoHideDuration={10000}
      action={SnackBarCloser}>
      <ThemeProvider theme={Theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <AuthProvider>
            <DataContextProvider>
              <TabsContextProvider>
                <App />
              </TabsContextProvider>
            </DataContextProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

export default Wrapper;
