/* eslint-disable no-console */
export enum LogLvl {
  DEBUG,
  INFO,
  WARN,
  ERROR,
}

export const env = process.env.REACT_APP_ENV;

export const isDev = (): boolean => env === "dev";
export const isStaging = (): boolean => env === "staging";
export const isProd = (): boolean => env === "production";

const getLogger = (lvl: LogLvl): ((...data: unknown[]) => void) | null => {
  switch (lvl) {
    case LogLvl.DEBUG:
      return isDev() ? console.debug : null;
    case LogLvl.WARN:
      return console.warn;
    case LogLvl.ERROR:
      return console.error;
    case LogLvl.INFO:
    default:
      return console.info;
  }
};

const LogIt = (lvl: LogLvl, ...args: unknown[]): void => {
  const logger = getLogger(lvl);
  if (logger) logger(...args);
};

export const log = {
  debug: (...args: unknown[]) => LogIt(LogLvl.DEBUG, ...args),
  info: (...args: unknown[]) => LogIt(LogLvl.INFO, ...args),
  warn: (...args: unknown[]) => LogIt(LogLvl.WARN, ...args),
  error: (...args: unknown[]) => LogIt(LogLvl.ERROR, ...args),
};
