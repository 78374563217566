import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { FC } from "react";
import { FormFieldItem } from "../../data/types/Type";

interface CheckboxListProps {
  items: FormFieldItem[];
  value: number[];
  onChange: (newValue: number[]) => void;
}

const updateValue = (value: number[], id: number, checked: boolean): number[] => {
  if (checked && !value.includes(id)) return [...value, id];
  if (!checked && value.includes(id)) return value.filter((v) => v !== id);
  return value;
};

const CheckboxList: FC<CheckboxListProps> = ({ items, value, onChange }) => (
  <FormGroup>
    {items.map((i) => (
      <FormControlLabel
        key={`cbç${i.id}`}
        control={
          <Checkbox
            checked={value.includes(i.id)}
            onChange={(_, checked) => onChange(updateValue(value, i.id, checked))}
          />
        }
        label={i.label}
      />
    ))}
  </FormGroup>
);

export default CheckboxList;
