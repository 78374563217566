import { TextField } from "@mui/material";
import { CSSProperties, ChangeEvent, FC } from "react";

interface NumberTextFieldProps {
  value: string;
  onChange: (n: string) => void;
  label?: string;
  style?: CSSProperties;
  fullWidth?: boolean;
  className?: string;
  integersOnly?: boolean;
}

const NumberTextField: FC<NumberTextFieldProps> = ({
  value,
  label,
  onChange,
  style,
  fullWidth,
  className,
  integersOnly,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    let rawValue = event.target.value.replace(/ /g, "").replace(/,/g, ".");
    if (integersOnly) rawValue = rawValue.replace(/\./g, "").replace(/,/g, "");
    if (!new RegExp("^[0-9.,.-]*$").test(rawValue)) return;
    if (
      rawValue.indexOf("-") !== rawValue.lastIndexOf("-") ||
      (rawValue.indexOf("-") !== 0 && rawValue.indexOf("-") !== -1)
    )
      return;
    if (rawValue.indexOf(",") !== rawValue.lastIndexOf(",")) return;
    onChange(rawValue);
  };

  return (
    <TextField
      style={style}
      label={label}
      value={value}
      onChange={handleChange}
      autoComplete="off"
      fullWidth={fullWidth}
      className={className}
    />
  );
};

export default NumberTextField;
