import { Checkbox, Divider, FormControlLabel, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import LocationForm, { defaultLocation } from "../forms/LocationForm";
import { Location, MissionCreation } from "../../data/types/Type";
import Button from "../common/Button";
import { DataContext } from "../../data/contexts/DataContext";
import styles from "../../styles/form.module.scss";
import { isLocationValid, isStringEmpty } from "../../data/utils/Utils";
import PhoneTextField from "../forms/PhoneTextField";

interface Step1Props {
  validate: (mission: Partial<MissionCreation>) => Promise<void>;
  isLoading: boolean;
}
const Step1: FC<Step1Props> = ({ validate, isLoading }) => {
  const { missionCreation } = useContext(DataContext);
  const [firstName, setFirstName] = useState(missionCreation?.firstName || "");
  const [lastName, setLastName] = useState(missionCreation?.lastName || "");
  const [role, setRole] = useState(missionCreation?.role || "");
  const [companyName, setCompanyName] = useState(missionCreation?.companyName || "");
  const [phone, setPhone] = useState(missionCreation?.phone || "");
  const [newsletter, setNewsletter] = useState(missionCreation?.newsletter || false);
  const [companyAddress, setCompanyAddress] = useState<Location>(missionCreation?.companyLocation || defaultLocation);
  const [billingAddress, setBillingAddress] = useState<Location>(missionCreation?.billingLocation || defaultLocation);
  const [contactAddress, setContactAddress] = useState<Location>(missionCreation?.contactLocation || defaultLocation);
  const [differentBillingAddress, setDifferentBillingAddress] = useState(
    !isStringEmpty(missionCreation?.billingLocation?.address),
  );
  const [differentContactAddress, setDifferentContactAddress] = useState(
    !isStringEmpty(missionCreation?.contactLocation?.address),
  );
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setFirstName(missionCreation?.firstName || "");
    setLastName(missionCreation?.lastName || "");
    setRole(missionCreation?.role || "");
    setCompanyName(missionCreation?.companyName || "");
    setPhone(missionCreation?.phone || "");
    setNewsletter(missionCreation?.newsletter || false);
    setCompanyAddress(missionCreation?.companyLocation || defaultLocation);
    setBillingAddress(missionCreation?.billingLocation || defaultLocation);
    setContactAddress(missionCreation?.contactLocation || defaultLocation);
    setDifferentBillingAddress(!isStringEmpty(missionCreation?.billingLocation?.address));
    setDifferentContactAddress(!isStringEmpty(missionCreation?.contactLocation?.address));
  }, [missionCreation]);

  const tryValidate = async (): Promise<void> => {
    if (
      lastName.length === 0 ||
      companyName.length === 0 ||
      phone.length === 0 ||
      !isLocationValid(companyAddress) ||
      !isLocationValid(billingAddress, !differentBillingAddress) ||
      !isLocationValid(contactAddress, !differentContactAddress)
    ) {
      enqueueSnackbar("Les champs comportant une * sont obligatoires, les adresses doivent être complètes", {
        variant: "error",
      });
    } else {
      await validate({
        firstName,
        lastName,
        contactLocation: differentContactAddress ? contactAddress : undefined,
        phone,
        role,
        newsletter,
        companyName,
        companyLocation: companyAddress,
        billingLocation: differentBillingAddress ? billingAddress : undefined,
      });
    }
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.form__block}>
          <Typography className={styles.form__title}>Votre société</Typography>
          <div className={styles.form__group}>
            <TextField
              label="Nom de la société *"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              fullWidth
              inputProps={{ maxLength: 250 }}
            />
          </div>
          <div className={styles.form__group}>
            <LocationForm title="Adresse *" location={companyAddress} setLocation={setCompanyAddress} />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={differentBillingAddress}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setBillingAddress(defaultLocation);
                    setDifferentBillingAddress(false);
                  } else {
                    setDifferentBillingAddress(true);
                  }
                }}
              />
            }
            label="Si adresse de facturation différente"
          />
          {differentBillingAddress && (
            <LocationForm
              title="Adresse de facturation"
              location={billingAddress}
              setLocation={setBillingAddress}
              isOptional
            />
          )}
        </div>
        <Divider />
        <div className={styles.form__block}>
          <Typography className={styles.form__title}>Vous</Typography>
          <div className={styles.form__group}>
            <TextField
              label="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              inputProps={{ maxLength: 200 }}
            />
            <TextField
              label="Nom *"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              inputProps={{ maxLength: 200 }}
            />
          </div>
          <div className={styles.form__group}>
            <TextField label="Fonction" value={role} onChange={(e) => setRole(e.target.value)} fullWidth />
            <PhoneTextField label="Mobile *" value={phone} onChange={(val) => setPhone(val)} fullWidth />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={differentContactAddress}
                onChange={(e) => {
                  if (!e.target.checked) {
                    setContactAddress(defaultLocation);
                    setDifferentContactAddress(false);
                  } else {
                    setDifferentContactAddress(true);
                  }
                }}
              />
            }
            label="Si adresse différente de celle de la société"
          />
          {differentContactAddress && (
            <LocationForm title="Votre adresse" location={contactAddress} setLocation={setContactAddress} isOptional />
          )}
        </div>
        <Divider />
        <div className={styles.form__block}>
          <Typography className={styles.form__title}>Pour garder le contact</Typography>
          <FormControlLabel
            control={<Checkbox checked={newsletter} onChange={(e) => setNewsletter(e.target.checked)} />}
            label="Acceptez-vous de recevoir des informations commerciales par email ?"
            className={styles.keepContact}
          />
        </div>
      </div>
      <Button label="Passer à l'étape suivante" onClick={tryValidate} isLoading={isLoading} />
    </>
  );
};

export default Step1;
