import { FC } from "react";
import "./IFrameRenderer.css";

const IFrameRenderer: FC = () => (
  <div className="container">
    <div className="above">Site d&apos;Agrodrone</div>
    <iframe src="/iframeMode" title="Planner app" />
    <div className="below">Bla bla bla...</div>
  </div>
);

export default IFrameRenderer;
