import { FC, PropsWithChildren, createContext, useState } from "react";

export const TabsContext = createContext<{
  tabValue: number;
  setTabValue: (value: number) => void;
}>({
  tabValue: 1,
  setTabValue: () => {},
});

const TabsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tabValue, setTabValue] = useState(1);
  return (
    <TabsContext.Provider
      value={{
        tabValue,
        setTabValue,
      }}>
      {children}
    </TabsContext.Provider>
  );
};

export default TabsContextProvider;
