import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { DataContext } from "../../data/contexts/DataContext";
import { MissionCreation } from "../../data/types/Type";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Header from "../common/Header";
import styles from "../../styles/reservations.module.scss";
import { AuthForm } from "../auth/AuthModal";
import { useSnackbar } from "notistack";
import { log } from "../../data/Logger";

const ReservationConfirmation: FC = () => (
  <div className={styles.confirmation}>
    <Typography variant="h6">
      Votre demande a bien été enregistrée,
      <br />
      nous allons prendre contact avec vous pour finaliser votre projet.
    </Typography>
  </div>
);

const ReservationForm: FC = () => {
  const { saveReservation, isAnonymous } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const validateOneStep =
    (nextStep: number) =>
    async (mission: Partial<MissionCreation>): Promise<void> => {
      log.debug(mission);
      setIsLoading(true);
      await saveReservation(mission, nextStep); // step being 0-based, nextStep will actually be the current step for the API
      setIsLoading(false);
      setStep(nextStep);
    };

  const validateFinalStep = async (mission: Partial<MissionCreation>): Promise<void> => {
    setIsLoading(true);
    const result = await saveReservation(mission, 3);
    if (result === 200) setStep(3);
    else enqueueSnackbar("Un problème est survenu, veuillez réessayer plus tard", { variant: "error" });
    setIsLoading(false);
  };

  const currentStep =
    step === 1 ? (
      <Step2 back={() => setStep(0)} validate={validateOneStep(2)} isLoading={isLoading} />
    ) : step === 2 ? (
      <Step3 back={() => setStep(1)} validate={validateFinalStep} isLoading={isLoading} />
    ) : step === 3 ? (
      <ReservationConfirmation />
    ) : (
      <Step1 validate={validateOneStep(1)} isLoading={isLoading} />
    );
  return (
    <div className={styles.reservationFormContainer}>
      <Header title="Réservation" />
      <div className={styles.formContainer}>
        {isAnonymous ? (
          <AuthForm title="Pour effectuer une demande de réservation, vous devez vous authentifier" />
        ) : (
          <>
            <Stepper activeStep={step} className={styles.stepper} alternativeLabel>
              <Step key="know" completed={step > 0}>
                <StepLabel>Vous connaître</StepLabel>
              </Step>
              <Step key="mission" completed={step > 1}>
                <StepLabel>L&apos;intervention</StepLabel>
              </Step>
              <Step key="details" completed={step > 2}>
                <StepLabel>Les modalités</StepLabel>
              </Step>
            </Stepper>
            {currentStep}
          </>
        )}
      </div>
    </div>
  );
};

export default ReservationForm;
