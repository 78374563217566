import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FC } from "react";

interface YesNoSelectorProps {
  value?: boolean;
  onChange: (newValue: boolean) => void;
  className?: string;
}

const YesNoSelector: FC<YesNoSelectorProps> = ({ value, onChange, className }) => (
  <FormControl className={className}>
    <RadioGroup
      aria-labelledby="radio-buttons-onlyOneSwornStatement"
      name="onlyOneSwornStatement-radio-group"
      value={typeof value === "undefined" ? "" : value ? "yes" : "no"}
      onChange={(_, newValue) => onChange(newValue === "yes")}
      row>
      <FormControlLabel value="yes" control={<Radio />} label="Oui" />
      <FormControlLabel value="no" control={<Radio />} label="Non" />
    </RadioGroup>
  </FormControl>
);

export default YesNoSelector;
