import { Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import Button from "../common/Button";
import { MissionCreation, MissionDetail, MissionDetailValue } from "../../data/types/Type";
import { DataContext } from "../../data/contexts/DataContext";
import FormRow from "../forms/FormRow";
import styles from "../../styles/form.module.scss";

interface Step3Props {
  back: () => void;
  validate: (mission: Partial<MissionCreation>) => Promise<void>;
  isLoading: boolean;
}

const Step3: FC<Step3Props> = ({ back, validate, isLoading }) => {
  const { missionCreation } = useContext(DataContext);
  const [details, setDetails] = useState<MissionDetail[]>([]);
  const tryValidate = async (): Promise<void> => {
    validate({ details });
  };
  const missionTypeSpecifics = missionCreation?.missionTypeConfig?.missionType || [];
  const constraints = missionCreation?.missionTypeConfig?.constraints || [];
  const updateDetail =
    (id: number) =>
    (value: MissionDetailValue): void => {
      let found = false;
      const newDetails = details.map((d): MissionDetail => {
        if (d.formFieldId === id) {
          found = true;
          return { formFieldId: id, value };
        }
        return d;
      });
      if (!found) newDetails.push({ formFieldId: id, value });
      setDetails(newDetails);
    };
  return (
    <div className={styles.form}>
      <Typography className={styles.padding_left}>
        Si vous n&apos;avez pas la réponse à toutes ces questions ou si vous avez besoin d&apos;assistance, remplissez
        celles que vous pouvez et nous prendrons contact avec vous
      </Typography>
      {missionTypeSpecifics.length > 0 ? (
        <div className={styles.form__block}>
          <Typography className={styles.form__title}>Modalités</Typography>
          {missionTypeSpecifics.map((mts) => (
            <FormRow
              field={mts}
              key={mts.code}
              updateValue={updateDetail(mts.id)}
              value={details.find((d) => d.formFieldId === mts.id)?.value}
            />
          ))}
        </div>
      ) : undefined}
      {constraints.length > 0 ? (
        <div className={styles.form__block}>
          <Typography className={styles.form__title}>Contraintes</Typography>
          {constraints.map((mts) => (
            <FormRow
              field={mts}
              key={mts.code}
              updateValue={updateDetail(mts.id)}
              value={details.find((d) => d.formFieldId === mts.id)?.value}
            />
          ))}
        </div>
      ) : undefined}
      <div className={styles.form__buttons}>
        <Button onClick={back} label="Retour à l'étape précédente" color="secondary" />
        <Button onClick={tryValidate} label="Terminer votre réservation" isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Step3;
