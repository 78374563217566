import { jwtDecode, JwtPayload } from "jwt-decode";
import { useSnackbar } from "notistack";
import { createContext, FC, useState } from "react";
import { getNewToken } from "../Api";
import { log } from "../Logger";
import { Auth, LOCAL_STORAGE_STORE, Tokens } from "../types/Type";
import { getItemFromLocalStorage, isFutureTimestamp } from "../utils/Utils";

export interface Authentication extends Tokens {
  accessExpires: number;
  refreshExpires: number;
}

export const AuthContext = createContext<{
  auth: Authentication | null | undefined;
  getToken: (isTokenMandatory?: boolean) => Promise<string | null>;
  wipeAuth: () => void;
  handleTokens: (tokens: Tokens) => void;
  refreshToken: (inAuth?: Authentication | null) => Promise<Auth | null>;
  isRefreshing: boolean;
  initLocalAuth: () => Authentication | null;
}>({
  auth: null,
  getToken: () => Promise.resolve(null),
  wipeAuth: () => {},
  handleTokens: () => {},
  refreshToken: () => Promise.resolve(null),
  isRefreshing: false,
  initLocalAuth: () => null,
});

export const AuthProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [auth, setAuth] = useState<Authentication | null | undefined>(undefined);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const initLocalAuth = (): Authentication | null => {
    const localAuth = getItemFromLocalStorage<Authentication>(LOCAL_STORAGE_STORE.AUTH_STORE);
    setAuth(localAuth);
    return localAuth;
  };

  const handleTokens = (tokens: Tokens): Authentication => {
    const refresh = jwtDecode(tokens.refresh_token) as JwtPayload;
    const access_token = jwtDecode(tokens.access_token) as JwtPayload;
    const result: Authentication = {
      access_token: tokens.access_token,
      refresh_token: tokens.refresh_token,
      accessExpires: access_token.exp || 0,
      refreshExpires: refresh.exp || 0,
    };

    localStorage.setItem(LOCAL_STORAGE_STORE.AUTH_STORE, JSON.stringify(result));
    setAuth(result);
    return result;
  };

  const wipeAuth = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_STORE.AUTH_STORE);
    setAuth(null);
  };

  const refreshTokenExpired = (): null => {
    enqueueSnackbar("Votre authentification a expiré, veuillez la renouveler", { variant: "error" });
    wipeAuth();
    return null;
  };

  const refreshToken = async (inAuth?: Authentication | null): Promise<Auth | null> => {
    const authToUse = inAuth || auth;
    if (typeof authToUse?.refresh_token === "string") {
      setIsRefreshing(true);
      const result = await getNewToken(authToUse?.refresh_token);
      if (typeof result === "object") {
        log.debug("Just successfully refreshed token!");
        handleTokens(result as Tokens);
        setIsRefreshing(false);
        return result;
      }
      setIsRefreshing(false);
    }
    return refreshTokenExpired();
  };

  const getToken = async (isTokenMandatory = true): Promise<string | null> => {
    if (!auth || (auth.refresh_token?.length || 0) === 0) {
      if (!isTokenMandatory) return null;
      throw new Error("No refresh token");
    }

    // If access token expires in more than 10 seconds
    if (isFutureTimestamp(auth.accessExpires)) return auth.access_token;

    // If refresh token is expired or expires in less than 10 seconds
    if (!isFutureTimestamp(auth.refreshExpires)) return refreshTokenExpired();

    const result = await refreshToken();
    return result?.access_token || null;
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        getToken,
        wipeAuth,
        handleTokens,
        refreshToken,
        isRefreshing,
        initLocalAuth,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
