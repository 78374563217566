import { IconButton, Modal, Paper, Typography } from "@mui/material";
import { LatLng, LatLngExpression } from "leaflet";
import { FC } from "react";
import { MapContainer } from "react-leaflet";
import Map from "./Map";
import styles from "../../styles/map.module.scss";
import { Close } from "@mui/icons-material";

interface MapModalProps {
  open: boolean;
  onClose: () => void;
  onClick: (coords: LatLng) => void;
  center?: LatLngExpression;
}

const MapModal: FC<MapModalProps> = ({ open, onClose, onClick, center }) => (
  <Modal open={open} onClose={onClose} className="modal-root">
    <Paper className="modal-paper">
      <div className={styles.modalHeader}>
        <Typography>Choix de la position, cliquez sur la carte</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <MapContainer
        className={styles.mapModalContainer}
        center={center || [46.3415466, 2.6019912]}
        zoom={Array.isArray(center) ? 16 : 6}
        scrollWheelZoom
        doubleClickZoom
        zoomControl={false}
        maxZoom={18}>
        <Map defaultIsSatellite fromModal onClick={onClick} />
      </MapContainer>
    </Paper>
  </Modal>
);

export default MapModal;
