import axios, { AxiosError } from "axios";
import { log } from "./Logger";
import { Auth, Mission, MissionCreation } from "./types/Type";

const AUTH_URL = process.env.REACT_APP_API_AUTH || "";
const MISSION_URL = process.env.REACT_APP_API_MISSION || "";

const addParamsToUrl = (url: string, params: Record<string, string>): string => {
  const paramsToAdd = Object.keys(params)
    .map((k) => `${k}=${params[k]}`)
    .join("&");
  return url.indexOf("?") === -1 ? `${url}?${paramsToAdd}` : `${url}&${paramsToAdd}`;
};

export const logAndNumber = (retValue: number, ...args: unknown[]): number => {
  log.error(...args);
  return retValue;
};

const send = async <T>(
  url: string,
  data: Record<string, unknown>,
  method: string = "POST",
  token?: string,
): Promise<T | number> => {
  try {
    log.debug("Sending to API", url, method, data);
    const result = await axios({
      url,
      method,
      headers: {
        "Content-Type": "application/json",
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
      data,
    });
    if (result.status !== 200) {
      return logAndNumber(result.status, "Error while posting data to API", url, result);
    }
    log.debug("Result from API", result.data);
    return result.data as T;
  } catch (err) {
    const error = err as AxiosError;
    console.error(error);
    return logAndNumber(error.response?.status || 500, "Error while posting data to API", url, err);
  }
};

const get = async <T>(url: string, token?: string): Promise<T | number> => {
  try {
    log.debug("Getting from API", url);
    const result = await axios({
      url,
      method: "GET",
      headers: {
        ...((token?.length || 0) !== 0 ? { authorization: `Bearer ${token}` } : {}),
      },
    });
    if (result.status !== 200) {
      return logAndNumber(result.status, "Error while getting data from API", url, result);
    }
    log.debug("Result from API", result.data);
    return result.data as T;
  } catch (err) {
    const error = err as AxiosError;
    return logAndNumber(error.response?.status || 500, "Error while getting data from API", url, err);
  }
};

export const getMagicLink = async (email: string): Promise<string | number> =>
  send<string>(AUTH_URL, { email: email }, "POST");

export const authenticationWithMagicLink = async (linkData: string): Promise<Auth | number> =>
  get<Auth>(addParamsToUrl(AUTH_URL, { c: linkData }));

export const getNewToken = async (refreshToken: string): Promise<Auth | number> => get<Auth>(AUTH_URL, refreshToken);

export const patchMission = async (
  mission: Partial<MissionCreation>,
  step: string,
  token: string,
): Promise<MissionCreation | number> =>
  send<MissionCreation>(
    addParamsToUrl(MISSION_URL, { step }),
    mission as unknown as Record<string, unknown>,
    "PATCH",
    token,
  );

export const getMission = async (missionId: string, token: string): Promise<Mission | number> =>
  get<Mission>(addParamsToUrl(MISSION_URL, { missionId }), token);

export const getMissions = async (token: string, resetCache = false): Promise<Mission[] | number> =>
  get<Mission[]>(addParamsToUrl(MISSION_URL, resetCache ? { resetCache: "true" } : {}), token);
