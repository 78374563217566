import { FC, useContext, useState } from "react";
import Button from "../common/Button";
import { MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { DataContext } from "../../data/contexts/DataContext";
import { Location, MissionCreation } from "../../data/types/Type";
import LocationForm, { defaultLocation } from "../forms/LocationForm";
import styles from "../../styles/form.module.scss";
import PhoneTextField from "../forms/PhoneTextField";
import NumberTextField from "../forms/NumberTextField";
import { useSnackbar } from "notistack";
import { getCountryName, isLocationValid, isStringEmpty } from "../../data/utils/Utils";
import MapModal from "../map/MapModal";
import { osmProvider } from "../map/Map";
import { LatLngExpression } from "leaflet";

interface Step2Props {
  back: () => void;
  validate: (mission: Partial<MissionCreation>) => Promise<void>;
  isLoading: boolean;
}

const Step2: FC<Step2Props> = ({ back, validate, isLoading }) => {
  const { missionTypes, missionCreation } = useContext(DataContext);
  const [missionGpsCoordinates, setMissionGpsCoordinates] = useState(
    missionCreation?.missionGpsCoordinates || { lat: "", lon: "" },
  );
  const [missionContactName, setMissionContactName] = useState(missionCreation?.missionContactName || "");
  const [missionContactPhone, setMissionContactPhone] = useState(missionCreation?.missionContactPhone || "");
  const [date, setDate] = useState(missionCreation?.date || "");
  const [interventionType, setInterventionType] = useState<string>(missionCreation?.missionType || "");
  const [interventionAddress, setInterventionAddress] = useState<Location>(
    isStringEmpty(missionCreation?.missionLocation?.countryCode)
      ? defaultLocation
      : missionCreation?.missionLocation || defaultLocation,
  );
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [mapModalCenter, setMapModalCenter] = useState<LatLngExpression | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const tryValidate = async (): Promise<void> => {
    if (
      isStringEmpty(interventionType) ||
      isStringEmpty(missionContactPhone) ||
      isStringEmpty(date) ||
      !isLocationValid(interventionAddress)
    ) {
      enqueueSnackbar("Les champs comportant une * sont obligatoires, l'adresse doit être complète", {
        variant: "error",
      });
    } else {
      await validate({
        missionType: interventionType,
        date,
        missionLocation: interventionAddress,
        missionContactName,
        missionContactPhone,
        missionGpsCoordinates:
          !isStringEmpty(missionGpsCoordinates.lat) || !isStringEmpty(missionGpsCoordinates.lon)
            ? missionGpsCoordinates
            : undefined,
      });
    }
  };
  const openMapModal = async (): Promise<void> => {
    if (!isLocationValid(interventionAddress)) {
      enqueueSnackbar("L'adresse du lieu d'intervention doit être complète", { variant: "error" });
    } else {
      const res = await osmProvider.search({
        query: `${interventionAddress.address}, ${interventionAddress.zipCode}, ${
          interventionAddress.city
        }, ${getCountryName(interventionAddress.countryCode)}`,
      });
      if (typeof res?.[0]?.y === "number" && typeof res?.[0]?.x === "number") setMapModalCenter([res[0].y, res[0].x]);
      setMapModalOpen(true);
    }
  };
  return (
    <div className={styles.form}>
      <div className={styles.form__block}>
        <div className={styles.form__row}>
          <Typography className={`${styles.form__title} ${styles.form__standalone}`}>Type de prestation *</Typography>
          <Select
            className={styles.form__notFullWidth}
            value={interventionType}
            onChange={(event: SelectChangeEvent) => setInterventionType(event.target.value)}>
            {missionTypes?.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Typography className={styles.form__title}>
          Date d&apos;intervention souhaitée * (un jour, une semaine, une période de l&apos;année)
        </Typography>
        <div className={styles.form__group}>
          <TextField value={date} onChange={(e) => setDate(e.target.value)} fullWidth />
        </div>
        <div className={styles.form__group}>
          <LocationForm
            title="Adresse du lieu d'intervention *"
            location={interventionAddress}
            setLocation={setInterventionAddress}
          />
        </div>
        <Typography className={styles.form__title}>Coordonnées pour localiser la zone d&apos;intervention</Typography>
        <div className={styles.form__group}>
          <NumberTextField
            label="Latitude"
            value={missionGpsCoordinates.lat}
            onChange={(v) => setMissionGpsCoordinates({ ...missionGpsCoordinates, lat: v })}
            fullWidth
          />
          <NumberTextField
            label="Longitude"
            value={missionGpsCoordinates.lon}
            onChange={(v) => setMissionGpsCoordinates({ ...missionGpsCoordinates, lon: v })}
            fullWidth
          />
          <Button
            className={styles.form__gpsChooseButton}
            onClick={openMapModal}
            label="Sélectionner sur la carte"
            color="secondary"
            size="small"
          />
        </div>
        <Typography className={styles.form__title}>Contact sur place</Typography>
        <div className={styles.form__group}>
          <TextField
            fullWidth
            label="Prénom et Nom du contact"
            value={missionContactName}
            onChange={(e) => setMissionContactName(e.target.value)}
          />
          <PhoneTextField
            fullWidth
            label="Mobile *"
            value={missionContactPhone}
            onChange={(val) => setMissionContactPhone(val)}
          />
        </div>
      </div>
      <div className={styles.form__buttons}>
        <Button onClick={back} label="Retour à l'étape précédente" color="secondary" />
        <Button onClick={tryValidate} label="Passer à la dernière étape" isLoading={isLoading} />
      </div>
      <MapModal
        open={mapModalOpen}
        onClose={() => setMapModalOpen(false)}
        center={mapModalCenter}
        onClick={(c) => setMissionGpsCoordinates({ lat: c.lat.toFixed(6), lon: c.lng.toFixed(6) })}
      />
    </div>
  );
};

export default Step2;
