import { FC } from "react";
import Lottie from "react-lottie";
import loaderFile from "../../lotties/DroneLoader.json";
import confirmationFile from "../../lotties/Sent.json";

export enum Animations {
  LOADER = "LOADER",
  CONFIRMATION = "CONFIRMATION",
}

interface AnimationProps {
  animation: Animations;
  maxWidth?: number;
  maxHeight?: number;
  dontLoop?: boolean;
}

const getLottieFile = (a: Animations): unknown => {
  switch (a) {
    case Animations.CONFIRMATION:
      return confirmationFile;
    default:
      return loaderFile;
  }
};

const Animation: FC<AnimationProps> = ({ animation, maxWidth, maxHeight, dontLoop = false }) => {
  const defaultOptions = {
    loop: !dontLoop,
    autoplay: true,
    animationData: getLottieFile(animation),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid",
    },
  };

  return (
    <div className="animationContainer" style={{ maxWidth, maxHeight }}>
      <Lottie options={defaultOptions} width={maxWidth} />
    </div>
  );
};

export default Animation;
