import { Modal, Paper, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import Animation, { Animations } from "../common/Animation";
import { DataContext } from "../../data/contexts/DataContext";
import { isEmailValid } from "../../data/utils/Utils";
import Button from "../common/Button";
import styles from "../../styles/authModal.module.scss";

export const AuthForm: FC<{ onClose?: () => void; onSend?: () => void; title?: string }> = ({
  onClose,
  onSend,
  title = "",
}) => {
  const { sendEmail } = useContext(DataContext);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOk, setIsOk] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const tryValidate = async (): Promise<void> => {
    onSend?.();
    if (!email || email.length === 0 || !isEmailValid(email)) {
      enqueueSnackbar("L'adresse email est invalide", { variant: "error" });
    } else {
      setIsLoading(true);
      const res = await sendEmail(email);
      if (res !== 200) enqueueSnackbar("Une erreur est survenue, merci de réessayer plus tard", { variant: "error" });
      else setIsOk(true);
      setIsLoading(false);
    }
  };
  return isLoading ? (
    <Animation animation={Animations.LOADER} />
  ) : isOk ? (
    <>
      <div className={styles.confirmContainer}>
        <Animation animation={Animations.CONFIRMATION} maxWidth={200} maxHeight={200} dontLoop />
        <Typography variant="h6" sx={{ textAlign: "center", maxWidth: "450px" }}>
          Dans quelques instants vous allez recevoir un email avec un lien qui va vous permettre de finaliser votre
          réservation.
        </Typography>
      </div>
      {typeof onClose === "undefined" ? undefined : <Button label="Fermer" onClick={onClose} />}
    </>
  ) : (
    <>
      <Typography variant="h6" className={styles.title}>
        {title.length > 0 ? title : "Votre demande de réservation"}
      </Typography>
      <div className={styles.form}>
        <Typography>Veuillez saisir votre adresse email :</Typography>
        <TextField
          className={styles.textField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") tryValidate();
          }}
        />
      </div>
      <Button label="Soumettre" onClick={tryValidate} />
    </>
  );
};

interface AuthModalProps {
  open: boolean;
  onClose: () => void;
}

const AuthModal: FC<AuthModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} className="modal-root">
      <Paper className="modal-paper">
        <AuthForm onClose={onClose} />
      </Paper>
    </Modal>
  );
};

export default AuthModal;
