import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet/dist/leaflet.css";
import { Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import Header from "./common/Header";
import { DataContext } from "../data/contexts/DataContext";
import AuthModal from "./auth/AuthModal";
import MapContainer from "./map/MapContainer";
import Button from "./common/Button";
import IFrameRenderer from "../global/IFrameRenderer";
import Authentication from "./auth/Authentication";
import "../styles/App.css";
import "../styles/LeafletOverrides.css";
import ReservationForm from "./reservation/ReservationForm";
import MissionDetails from "./mission/MissionDetails";

const FullScreenMessage: FC<{ message?: string }> = ({ message = "Page introuvable" }) => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="notFound">
        <Typography variant="h5">{message}</Typography>
        <Button label="Revenir à l'accueil" onClick={() => navigate("/")} />
      </div>
    </>
  );
};

const App: FC = () => {
  const { isAnonymous, isAdmin } = useContext(DataContext);
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const onReservationButtonClick = (): void => {
    if (isAnonymous) setOpenAuthModal(true);
    else router.navigate("/reservation");
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MapContainer onReservationButtonClick={onReservationButtonClick} />,
      errorElement: <FullScreenMessage />,
    },
    {
      path: "iframeMode",
      element: <MapContainer onReservationButtonClick={onReservationButtonClick} />,
    },
    {
      path: "iframe",
      element: <IFrameRenderer />,
    },
    {
      path: "auth/:userId?/:correlationId?",
      element: <Authentication />,
    },
    {
      path: "reservation",
      element: <ReservationForm />,
    },
    {
      path: "mission/:missionId?",
      element: isAdmin ? <MissionDetails /> : <FullScreenMessage message="Vous n'avez pas accès à cette page" />,
      errorElement: <FullScreenMessage message="Une erreur est survenue" />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
      <AuthModal open={openAuthModal && isAnonymous} onClose={() => setOpenAuthModal(false)} />
    </div>
  );
};

export default App;
