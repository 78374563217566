import { TextField } from "@mui/material";
import { CSSProperties, ChangeEvent, FC, useEffect, useState } from "react";

export const formatPhoneNumber = (number?: string): string => {
  if (number?.startsWith("0")) {
    if (number.length === 10) {
      // FR starting with 0
      return `${number.substring(0, 2)} ${number.substring(2, 4)} ${number.substring(4, 6)} ${number.substring(
        6,
        8,
      )} ${number.substring(8, 10)}`;
    }
    if (number.length === 9) {
      // BE starting with 0
      return `${number.substring(0, 2)} ${number.substring(2, 5)} ${number.substring(5, 7)} ${number.substring(7, 9)}`;
    }
  }
  if (number?.startsWith("+33") && number.length === 12) {
    return `${number.substring(0, 4)} ${number.substring(4, 6)} ${number.substring(6, 8)} ${number.substring(
      8,
      10,
    )} ${number.substring(10, 12)}`;
  }

  // Belgium : +32
  if (number?.startsWith("+32") && number.length === 11) {
    return `${number.substring(0, 4)} ${number.substring(4, 7)} ${number.substring(7, 9)} ${number.substring(9, 11)}`;
  }
  return number || "";
};

interface PhoneTextFieldProps {
  value: string;
  label: string;
  onChange: (n: string) => void;
  style?: CSSProperties;
  fullWidth?: boolean;
}

const PhoneTextField: FC<PhoneTextFieldProps> = ({ value, label, onChange, style, fullWidth }) => {
  const [formattedValue, setFormattedValue] = useState(formatPhoneNumber(value));
  useEffect(() => {
    if (formatPhoneNumber(value) !== formattedValue) setFormattedValue(formatPhoneNumber(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const rawValue = event.target.value.replace(/ /g, "").replace(/-/g, "").replace(/\./g, "");
    if (!new RegExp("^[0-9 +]*$").test(rawValue)) return;
    const newFormattedValue = formatPhoneNumber(rawValue);
    setFormattedValue(newFormattedValue);
    onChange(rawValue);
  };

  const maxLength = (): number => {
    if (formattedValue.startsWith("0")) return 14;
    if (formattedValue.startsWith("+33")) return 16;
    if (formattedValue.startsWith("+32")) return 12;
    return 10;
  };

  return (
    <TextField
      style={style}
      label={label}
      value={formattedValue}
      inputProps={{
        maxLength: maxLength(),
      }}
      onChange={handleChange}
      autoComplete="off"
      fullWidth={fullWidth}
    />
  );
};

export default PhoneTextField;
